import { ThemeProvider as EmotionThemeProvider } from '@emotion/react'
import React, { memo } from 'react'

import { MediaContextProvider } from './media'

export type Theme = typeof theme

export const theme = {
  colors: {
    variants: {
      primaryLight: '#4E738A',
      primaryDark: '#992237',
      neutralLight1: '#FFFFFF',
      neutralLight2: '#FDFBF8',
      neutralLight3: '#F5EDE2',
      neutralLight4: '#D4CDC3',
      neutralDark1: '#000000',
      neutralDark2: '#1E1E1C',
      neutralDark3: '#6E6E69',
      neutralDark4: '#272725',
      danger: '#ED0F36',
    },
  },
  fontFamily: {
    heading: "'Be Vietnam Pro', sans-serif",
    paragraph: "'Inter', sans-serif",
  },
}

export const ThemeProvider = memo(function ThemeProvider({ children }: any) {
  return (
    <EmotionThemeProvider theme={theme}>
      <MediaContextProvider>{children}</MediaContextProvider>
    </EmotionThemeProvider>
  )
})
